import React, { useState, useEffect } from "react";

import * as Styles from "./progress-bar.module.css";

const MeshStepProgressBar = ({ step }) => {
    const [meshCustomzationState, setMeshCustomzationState] = useState(false);
    const [previewState, setPreviewState] = useState(false);
    const [orderGenerationState, setOrderGenerationState] = useState(false);
    const [orderSimuationState, setOrderSimuationState] = useState(null);

    useEffect(() => {
        switch (step) {
            case 1:
                setMeshCustomzationState(true);
                break;
            case 2:
                setMeshCustomzationState(true);
                setPreviewState(true);
                break;
            case 3:
                setMeshCustomzationState(true);
                setPreviewState(true);
                setOrderGenerationState(true);
                break;
            case 4:
                setMeshCustomzationState(true);
                setPreviewState(true);
                setOrderGenerationState(true);
                setOrderSimuationState(true);
                break;
            default:
                setMeshCustomzationState(false);
                setPreviewState(false);
                setOrderGenerationState(false);
                setOrderSimuationState(false);
        }
    }, [step]);

    return (
        <div className={Styles.container}>
            <ul className={Styles.progressbar}>
                <li className={meshCustomzationState ? Styles.active : null}>
                    Mesh Customization
                </li>
                <li className={previewState ? Styles.active : null}>
                    Preview Mesh
                </li>
                <li className={orderGenerationState ? Styles.active : null}>
                    Order Mesh Generation
                </li>
                <li className={orderSimuationState ? Styles.active : null}>
                    Order Real Simulation
                </li>
            </ul>
        </div>
    );
};

export default MeshStepProgressBar;

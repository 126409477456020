import React, { useContext, useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Router } from "@reach/router";
import Elm from "react-elm-components";

import { CurrentUserContext } from "../providers/auth";
import {
    TooltipProvider,
    TooltipWebComponentsContext,
} from "../providers/custom-elements";

import Layout from "../components/layout";
import Seo from "../components/seo";
import Loading from "../components/loading";
import MeshStepProgressBar from "../components/progress-bar";

import NewOrderApp from "../ElmApp/Page/Order/New.elm";

const Routing = (props) => {
    let { CPAS_API_SERVICE_URI, modelVersions } = props.data.site.siteMetadata;

    return (
        <Layout>
            <TooltipProvider>
                <Router>
                    <NewOrder
                        path="order/p/:projectId/j/:jobId"
                        CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                        modelVersions={modelVersions}
                    />
                    <NewOrder
                        path="order/s/:meshSpecId"
                        CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                        modelVersions={modelVersions}
                    />
                </Router>
            </TooltipProvider>
        </Layout>
    );
};

export default Routing;

export const query = graphql`
    query {
        site {
            siteMetadata {
                CPAS_API_SERVICE_URI
                modelVersions
            }
        }
    }
`;

/// pages

const NewOrder = ({
    CPAS_API_SERVICE_URI,
    meshSpecId,
    projectId,
    jobId,
    modelVersions,
}) => {
    const currentUser = useContext(CurrentUserContext);
    const tooltip = useContext(TooltipWebComponentsContext);

    const [view, setView] = useState(<Loading />);
    const [idToken, setIdToken] = useState(null);
    const [step, setStep] = useState(2);

    useEffect(() => {
        currentUser.getIdToken().then((idToken) => setIdToken(idToken));
    }, [currentUser]);

    useEffect(() => {
        if (idToken && tooltip.ready) {
            setView(
                <>
                    <MeshStepProgressBar step={step} />
                    <Elm
                        key={JSON.stringify(idToken)}
                        src={NewOrderApp.Elm.Page.Order.New}
                        flags={{
                            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                            idToken: idToken,
                            meshSpecId: meshSpecId ? meshSpecId : null,
                            fromJob:
                                projectId && jobId
                                    ? { projectId, jobId }
                                    : null,
                            modelVersions,
                        }}
                        ports={(ports) => {
                            ports.back.subscribe(() => {
                                window.history.back();
                            });
                            ports.fromJob.subscribe(() => {
                                setStep(3);
                            });
                            ports.orderedGenJob.subscribe(() => {
                                setStep(3);
                            });
                            ports.orderedSimJob.subscribe(() => {
                                setStep(4);
                            });
                            ports.goToGenJobList.subscribe(() => {
                                navigate("/job/meshgen");
                            });
                            ports.goToRealSimJobList.subscribe(() => {
                                navigate("/job/realsim");
                            });
                            ports.goToSWJobList.subscribe(() => {
                                navigate("/job/swtest");
                            });
                        }}
                    />
                </>
            );
        } else {
            setView("Not allowed to view this page");
        }
    }, [
        idToken,
        tooltip,
        CPAS_API_SERVICE_URI,
        meshSpecId,
        projectId,
        jobId,
        modelVersions,
        step,
    ]);

    return (
        <>
            <Seo title="Place Order" />
            {view}
        </>
    );
};
